
import { EMAIL_VALIDATION_MUTATION } from "@/constants/gql/user";
import { useMutation } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { BACKGROUND_CONFETTI } from "../constants/imageUrls";

export default defineComponent({
  name: "ValidationCode",
  inheritAttrs: false,
  setup() {
    const route = useRoute();
    const router = useRouter();
    const validationHash = route.params.code;
    const { mutate, loading } = useMutation(EMAIL_VALIDATION_MUTATION);
    const isLoading = ref<string>("");
    const windowWidth = ref<number>(window.innerWidth);
    const isDisabled = ref<boolean>(false);

    const resizeScreen = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
      const { data } = await mutate({
        validationHash
      });

      if (data && data.emailValidation != "error") {
        localStorage.setItem("token", data.emailValidation);
      } else {
        isDisabled.value = true;
        router.push("/");
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
    });

    const reload = () => {
      isLoading.value = "is-loading";
      isDisabled.value = true;
      window.location.reload();
      router.push("/");
    };

    return {
      reload,
      windowWidth,
      BACKGROUND_CONFETTI,
      isLoading,
      loading,
      isDisabled
    };
  }
});
